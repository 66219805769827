import React from 'react';

const DoodleJumpWindow = () => {
    return (
        <div style={{ width: '370px', height: '640px' }}> {/* Adjusted size */}
            <iframe
                src={`${process.env.PUBLIC_URL}/Doodle/doodle_jump.html`}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Doodle Jump Game"
            />
        </div>
    );
};

export default DoodleJumpWindow;

